import * as React from "react";

import {
  SchedulerForm,
  SchedulerFormProps,
} from "@progress/kendo-react-scheduler";

import { CustomFormEditor } from "./custom_form_editor";
import { CustomDialog } from "./custom_dialog";

export const FormWithCustomEditor = (props: SchedulerFormProps) => {
  return (
    <SchedulerForm {...props} editor={CustomFormEditor} dialog={CustomDialog} />
  );
};
