import * as React from "react";
import { UploadListItemProps } from "@progress/kendo-react-upload";
import { Button } from "@progress/kendo-react-buttons";

export const CustomFileList = (props: any) => {
  const handleRemove = (file: UploadListItemProps) => {
    if (props.onRemove) {
      props.onRemove(file);
    }
  };

  return (
    <div>
      {props.files.map((file: any) => (
        <Button
          className="delete-text"
          onClick={() => handleRemove(file)} // Trigger the remove action
        >
          Remove
        </Button>
      ))}
    </div>
  );
};
