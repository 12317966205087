import axios from "axios";
import Config from "../services/Config";

const instance = axios.create(Config);

export const setupAxiosInterceptors = (store: any, dispatch: any) => {
  instance.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.data.code === "UNAUTHORIZED") {
      }
      return Promise.reject(error);
    }
  );
};

export default instance;
