import Api from "../Api";

export const samplePostAPI = (params: any) => {
  return Api.post("/example/usrs", params);
};

export const sampleGetAPI = () => {
  const header = {
    accept: "application/json;odata.metadata=minimal;odata.streaming=true",
  };
  return Api.get("/odata/SpaceTypes", { headers: header });
};

export const apiAuthenticateLogin = (baseURL: any, headers: any) => {
  return Api.post(
    `${baseURL}Login`,
    {},
    {
      headers: headers,
    }
  );
};
