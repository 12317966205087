import React, { useEffect, useRef, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import "../src/RoomBooking.scss";
import "../src/Common.scss";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { commonBaseUrl, commonImageUrl } from "./services/CommonUtils";
import "../src/FoodOrders.scss";
import FoodMenu from "./orders/FoodMenu";
import Categories from "./orders/Categories";
import OrderSummary from "./orders/OrderSummary";

interface CategoryItem {
  id: number;
  name: string;
}

interface FoodItem {
  id: number;
  name: string;
  image: string;
  description: string;
  maxQty: number;
  price: number;
  type: string;
  categoryId: number;
}

interface CartItem {
  id: number;
  name: string;
  typeId: string;
  originalPrice: number;
  fixedPrice: number;
  price: number;
  quantity: number;
  maxQty: number;
}

interface packageDetails {
  id: number;
  packageName: string;
  inclusion: string;
  exclusion: string;
  isActive: boolean;
  foodList: [
    {
      id: number;
      packageHeaderId: number;
      categoryId: number;
      subCategoryId: number;
      packageQty: number;
      availableQty: number;
      subCategory: {
        id: number;
        categoryId: number;
        name: string;
        isAvailable: boolean;
        price: number;
        typeId: number;
        fixedQuantity: number;
        quantity: number;
        jsonDate: string;
        description: string;
        isActive: boolean;
        isDelete: boolean;
        createdBy: number;
        createdOn: string;
        modifiedBy: number;
        modifiedOn: string;
        fromDate: string;
        toDate: string;
      };
    }
  ];
}

interface complimentaryItem {
  bookingId: number;
  foodDetails: foodDetails[];
}

interface foodDetails {
  categoryId: number;
  subCategoryId: number;
  consumedQty: number;
  fixedQty: number;
  foodPrice: number;
}

export const FoodOrders = () => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();

  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const anchorRef = useRef(null);
  const popupRef = useRef(null);
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [alertContent, setAlertContent] = React.useState<String>("");

  const [categoryList, setCategoryList] = useState<CategoryItem[]>([]);
  const [packageList, setPackageList] = useState<packageDetails>();

  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [cart, setCart] = useState<CartItem[]>([]);
  const [compliment, setCompliment] = useState<foodDetails[]>([]);

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    var savedPreference = localStorage.getItem("name");
    if (savedPreference !== "" && savedPreference !== null) {
      setLoggedIn(true);
    }
    getProfile();
  });

  // Close popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click is outside both the popup and the anchor (profile image)
      const path = event.composedPath ? event.composedPath() : event.path;
      if (
        !path.includes(popupRef.current) &&
        !path.includes(anchorRef.current)
      ) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const getProfile = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${localStorage.getItem(
          "id"
        )} and contains(reftype, 'Profile')`
      );
      getPackageUtilization();
      if (response.data.value.length !== 0) {
        if (
          response.data.value[response.data.value.length - 1].Name ===
          "A6CC0B0F4DP2012E"
        ) {
          setProfileImage("");
        } else {
          const fileURL: any =
            imageURL +
            response.data.value[response.data.value.length - 1].ImagePath;
          setProfileImage(fileURL);
        }
      }
    } catch (err) {
      alert(err);
    }
  };

  const getPackageUtilization = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/GetPackagecomplementryFoods?bookingId=${sessionStorage.getItem(
          "booking_id"
        )}`
      );
      getCategory();
      setPackageList(response.data);
    } catch (err) {
      alert(err);
    }
  };

  const getCategory = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/Categorys?$filter=IsAvailable eq true`
      );
      if (response.data.value.length !== 0) {
        const categories = [];
        const responseData = response.data.value;
        for (let c = 0; c < responseData.length; c++) {
          const categorieData = {
            id: responseData[c].Id,
            name: responseData[c].Name,
          };
          categories.push(categorieData);
        }
        setCategoryList(categories);
      }
      setLoading(false);
    } catch (err) {
      alert(err);
    }
  };

  // Login status
  const handleLoginLogout = () => {
    setLogoutDialog(true);
    setShowPopup(false); // Close the popup after clicking
  };

  // Popup visibility
  const handleProfileClick = () => {
    setShowPopup(!showPopup);
  };

  // menu flow
  const navigateMyProfile = () => {
    navigate("/myprofile");
  };

  const logoutHandler = (isLogout: any) => {
    if (isLogout === true) {
      removeUserDetails();
    }
    setLogoutDialog(false);
  };

  const removeUserDetails = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("customer_type");
    localStorage.removeItem("company_name");
    localStorage.removeItem("name");
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    localStorage.removeItem("address");
    localStorage.removeItem("address2");
    localStorage.removeItem("country");
    localStorage.removeItem("state");
    localStorage.removeItem("city");
    localStorage.removeItem("last_name");
    localStorage.removeItem("pin_code");
    localStorage.removeItem("kyc_document_id");
    localStorage.removeItem("gst_number");
    localStorage.removeItem("created");
    localStorage.removeItem("token");
    navigate("/");
  };

  // Order flow

  const handleAddToCart = (foodItem: FoodItem) => {
    if (cart.length !== 0) {
      const existingItem = cart.find((cartItem) => cartItem.id === foodItem.id);
      if (foodItem.id === existingItem?.id) {
        setVisible(true);
        setAlertContent(
          `${foodItem.name} food already added your order summary list`
        );
      } else {
        let existNew = false;
        if (packageList && packageList.foodList) {
          for (let f = 0; f < packageList.foodList.length; f++) {
            if (foodItem.id === packageList.foodList[f].subCategory.id) {
              setCompliment((prevCart: any) => {
                return [
                  ...prevCart,
                  {
                    categoryId: foodItem.categoryId,
                    subCategoryId: foodItem.id,
                    consumedQty: 1,
                    fixedQty: packageList.foodList[f].availableQty,
                    foodPrice: foodItem.price,
                  },
                ];
              });
              existNew = true;
              break;
            }
          }
        }
        setCart((prevCart) => {
          return [
            ...prevCart,
            {
              id: foodItem.id,
              name: foodItem.name,
              image: foodItem.image,
              typeId: foodItem.type,
              originalPrice: existNew === true ? 0 : foodItem.price,
              fixedPrice: foodItem.price,
              price: foodItem.price,
              quantity: 1,
              maxQty: foodItem.maxQty,
            },
          ];
        });
      }
    } else {
      let checkNew = false;
      if (packageList && packageList.foodList) {
        for (let f = 0; f < packageList.foodList.length; f++) {
          if (foodItem.id === packageList.foodList[f].subCategory.id) {
            setCompliment((prevCart: any) => {
              return [
                ...prevCart,
                {
                  categoryId: foodItem.categoryId,
                  subCategoryId: foodItem.id,
                  consumedQty: 1,
                  fixedQty: packageList.foodList[f].availableQty,
                  foodPrice: foodItem.price,
                },
              ];
            });
            checkNew = true;
            break;
          }
        }
      }
      setCart((prevCart) => {
        return [
          ...prevCart,
          {
            id: foodItem.id,
            name: foodItem.name,
            image: foodItem.image,
            typeId: foodItem.type,
            originalPrice: checkNew === true ? 0 : foodItem.price,
            fixedPrice: foodItem.price,
            price: foodItem.price,
            quantity: 1,
            maxQty: foodItem.maxQty,
          },
        ];
      });
    }
  };

  const handleUpdateCart = (
    updatedCart: CartItem[],
    updateCompliment: foodDetails[]
  ) => {
    setCart(updatedCart);
    setCompliment(updateCompliment);
    if (updatedCart.length === 0 && updateCompliment.length === 0) {
      setLoading(true);
      getPackageUtilization();
    }
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const navigateMyOrders = () => {
    navigate("/myorders");
  };

  const navigateNextScreen = () => {
    navigate("/bookings");
  };

  return (
    <div className="booking-container">
      <AppBar className="header-row">
        <AppBarSection>
          <img
            src={require("./assets/images/logo.png")}
            alt="Logo"
            style={{ height: "40px", marginRight: "20px" }}
          />
        </AppBarSection>
        <AppBarSection className="menu-alignment">
          <Label className="top-view top-selected">
            <Link to="/">Book a Space</Link>
          </Label>
        </AppBarSection>
        <AppBarSpacer style={{ flexGrow: 1 }} />
        <AppBarSection className="top-view">
          {profileImage === "" ? (
            <img
              ref={anchorRef}
              src={require("./assets/images/profile.png")}
              alt="Profile"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileClick}
            />
          ) : (
            <img
              ref={anchorRef}
              src={profileImage}
              alt="Profile"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileClick}
            />
          )}
          <Popup
            anchor={anchorRef.current}
            collision={{
              horizontal: "fit",
              vertical: "flip",
            }}
            show={showPopup}
            popupClass={"popup-content"}
            animate={false}
          >
            <div ref={popupRef} style={{ padding: "10px" }}>
              {loggedIn && (
                <div>
                  <Label
                    style={{ color: "#42B677" }}
                  >{`Welcome ${localStorage.getItem("name")}`}</Label>
                </div>
              )}
              {loggedIn && (
                <div>
                  <Button
                    fillMode="link"
                    className="popup-button-block popup-button-color"
                    onClick={navigateNextScreen}
                  >
                    My Bookings
                  </Button>
                  <Button
                    fillMode="link"
                    className="popup-button-block"
                    onClick={navigateMyOrders}
                  >
                    My Orders
                  </Button>
                  <Button
                    fillMode="link"
                    className="popup-button-block"
                    onClick={navigateMyProfile}
                  >
                    My Profile
                  </Button>
                </div>
              )}
              <Button fillMode="link" onClick={handleLoginLogout}>
                {loggedIn ? "Logout" : "Login"}
              </Button>
            </div>
          </Popup>
        </AppBarSection>
      </AppBar>
      <h2 className="bookings-header">Food Menus</h2>
      {loading && (
        <div className="loading-screen">
          <Loader size="large" type={"infinite-spinner"} />
        </div>
      )}
      {!loading && (
        <div className="booking-container">
          {packageList && packageList?.foodList.length > 0 && (
            <div className="order-page order-border-flow ">
              <div className="complimentary-section order-border-flow ">
                <Label className="complimentary-header">
                  Complimentary: <span> Package Inclusion</span>
                </Label>
                <div>
                  <Label>Package Name: {packageList?.packageName}</Label>
                </div>
                <div>
                  <Label className="complimentary-sub-header">
                    Food Details:
                  </Label>
                </div>
                <div className="custom-details-body">
                  <div className="custom-detail-item aligh-complimentary">
                    <Label className="custom-label">Food Type:</Label>
                  </div>
                  <div
                    className="custom-detail-item aligh-complimentary"
                    style={{ alignItems: "center" }}
                  >
                    <Label className="custom-label">Food Name:</Label>
                  </div>
                  <div
                    className="custom-detail-item aligh-complimentary"
                    style={{ alignItems: "center" }}
                  >
                    <Label className="custom-label">Quantity:</Label>
                  </div>
                </div>
                {packageList &&
                  packageList?.foodList.map((foodItem, index) => (
                    <div className="custom-food-details-body">
                      <div className="custom-detail-item aligh-complimentary">
                        <Label className="custom-value">
                          <img
                            src={require(`./assets/images/${
                              Number(foodItem.subCategory.typeId) === 1
                                ? `veg`
                                : `non`
                            }.png`)}
                            alt="Lounge Space"
                            className="food-type-logo"
                          />
                          {Number(foodItem.subCategory.typeId) === 1
                            ? "Veg"
                            : "Non-Veg"}
                        </Label>
                      </div>
                      <div
                        className="custom-detail-item aligh-complimentary"
                        style={{ alignItems: "center" }}
                      >
                        <Label
                          className="custom-value"
                          style={{
                            textAlign: "left",
                            whiteSpace: "normal", // Allows text to wrap
                            overflow: "hidden", // Ensures content doesn't overflow
                            textOverflow: "ellipsis", // Optional: Adds ellipsis for overflowed text
                            display: "block", // Ensures the label behaves as a block element for wrapping
                          }}
                        >
                          {foodItem.subCategory.name}
                        </Label>
                      </div>
                      <div
                        className="custom-detail-item aligh-complimentary"
                        style={{ alignItems: "center" }}
                      >
                        <Label className="custom-value">
                          {foodItem.availableQty}
                        </Label>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div className="order-page order-border-flow ">
            {categoryList.length !== 0 && (
              <div className="categories-section">
                <Categories
                  categories={categoryList}
                  onSelectCategory={setSelectedCategory}
                />
              </div>
            )}
            {selectedCategory !== null && (
              <div className="menu-section order-border-flow ">
                <FoodMenu
                  categoryId={selectedCategory}
                  onAddToCart={handleAddToCart}
                />
              </div>
            )}
            {cart.length !== 0 && (
              <div className="order-summary-section order-border-flow ">
                <OrderSummary
                  cart={cart}
                  compliment={compliment}
                  onUpdateCart={handleUpdateCart}
                />
              </div>
            )}
            {categoryList.length === 0 && selectedCategory === null && (
              <div className="center-screen">No Food data found</div>
            )}
          </div>
        </div>
      )}
      {visible && (
        <Dialog
          title={"Message"}
          onClose={toggleDialog}
          style={{ alignItems: "center" }}
        >
          <Label
            style={{
              height: "80px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {alertContent}
            <div
              style={{
                padding: "20px",
              }}
            />
            <Button
              className="alert-button"
              type="button"
              onClick={toggleDialog}
            >
              Ok
            </Button>
          </Label>
        </Dialog>
      )}
      {/* Logout dialog */}
      {logoutDialog && (
        <Dialog
          onClose={() => setLogoutDialog(false)}
          className="dialog-container"
        >
          <div className="dialog-content">
            <Label className="dialog-heading">Alert Dialog</Label>
            <Label>Are you sure you want to logout?</Label>
            <div className="login-button-content">
              <Button
                className="login-button-dialog"
                onClick={(e) => logoutHandler(true)}
              >
                Yes
              </Button>
              <Button
                className="login-button-dialog"
                onClick={(e) => logoutHandler(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
