import React, { useEffect, useRef, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import "../src/RoomBooking.scss";
import "../src/Common.scss";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { commonBaseUrl, commonImageUrl } from "./services/CommonUtils";

interface bookingList {
  id: number;
  bookingId: string;
  spaceId: string;
  spaceName: string;
  status: string;
  statusTextColor: string;
  statusColor: string;
  durationName: string;
  bookingFrom: string;
  bookingTo: string;
  spaceImage: string;
  spaceSeatCount: number;
}

export const MyBookingsList = () => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();

  const defaultShot = { id: null, text: "Sort", value: null };
  const [myBookingDetails, setMyBookingDetails] = useState<any>();
  const [shotValue, setshotValue] = useState<any>(defaultShot);
  const [bookingDetailsData, setBookingDetailsData] = useState<bookingList[]>(
    []
  );
  const [bookingDetailsOnGoingData, setBookingDetailsOnGoingData] = useState<
    bookingList[]
  >([]);
  const [bookingDetailsUpcomingData, setBookingDetailsUpcomingData] = useState<
    bookingList[]
  >([]);
  const [bookingDetailsCompletedData, setBookingDetailsCompletedData] =
    useState<bookingList[]>([]);
  const [bookingDetailsCancelledData, setBookingDetailsCancelledData] =
    useState<bookingList[]>([]);

  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const anchorRef = useRef(null);
  const popupRef = useRef(null);
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const [logoutDialog, setLogoutDialog] = useState(false); // State to control the popup

  // Convert Date Server to MMM DD YYYY
  const convertDate = (dateString: any) => {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Define month names to convert the numeric month to abbreviated form
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the parts of the date you need
    const year = date.getFullYear();
    const month = months[date.getMonth()]; // Get the month as a string
    const day = date.getDate();

    // Format it in the desired format "Sep 27 2024"
    return `${day} ${month} ${year}`;
  };

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    var savedPreference = localStorage.getItem("name");
    if (savedPreference !== "" && savedPreference !== null) {
      setLoggedIn(true);
    }
    getProfile();
    getBookingList(false, false);
  });

  // Close popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click is outside both the popup and the anchor (profile image)
      const path = event.composedPath ? event.composedPath() : event.path;
      if (
        !path.includes(popupRef.current) &&
        !path.includes(anchorRef.current)
      ) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when component unmounts or showPopup changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const getProfile = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${localStorage.getItem(
          "id"
        )} and contains(reftype, 'Profile')`
      );
      if (response.data.value.length !== 0) {
        if (
          response.data.value[response.data.value.length - 1].Name ===
          "A6CC0B0F4DP2012E"
        ) {
          setProfileImage("");
        } else {
          const fileURL: any =
            imageURL +
            response.data.value[response.data.value.length - 1].ImagePath;
          setProfileImage(fileURL);
        }
      } else {
      }
    } catch (err) {
      alert(err);
    }
  };

  // Initial load API's
  const getBookingList = async (shotName: boolean, shotDate: boolean) => {
    setLoading(true);
    let fullUrl = "";
    if (shotDate === true) {
      fullUrl = `${baseURL}odata/Bookings?$filter=(IsActive eq true)and(UserId eq ${localStorage.getItem(
        "id"
      )})&$orderby=BookingDateTime desc`;
    } else {
      fullUrl = `${baseURL}odata/Bookings?$filter=(IsActive eq true)and(UserId eq ${localStorage.getItem(
        "id"
      )})`;
    }
    try {
      const response = await axios.get(fullUrl);
      const bookings: any[] = [];
      const responseData = response.data.value;
      setMyBookingDetails(responseData);
      for (let s = 0; s < responseData.length; s++) {
        const bookingData = {
          id: response.data.value[s].Id,
          bookingId: response.data.value[s].BookingId,
          spaceId: response.data.value[s].SpaceId,
          spaceName: response.data.value[s].SpaceId.toString(),
          status: response.data.value[s].BookingStatus,
          statusTextColor: "",
          statusColor: "",
          durationName: response.data.value[s].DurationId.toString(),
          bookingFrom: convertDate(response.data.value[s].BookingDatetime),
          bookingTo: convertDate(response.data.value[s].BookingDatetime),
          spaceImage: "",
          spaceSeatCount: 0,
        };
        bookings.push(bookingData);
      }
      getSpaceList(bookings, shotName);
    } catch (err) {
      alert(err);
    }
  };

  const getSpaceList = async (responseData: any, shotName: any) => {
    try {
      const updatedSpaces = await Promise.all(
        responseData.map(async (item: bookingList) => {
          const spaceResponse = await axios.get(
            `${baseURL}odata/SpaceHeaders(${item.spaceName})?$expand=SpaceDetails($filter=DurationId eq ${item.durationName})`
          );
          item.spaceName = spaceResponse.data.SpaceName || "";
          item.spaceSeatCount = spaceResponse.data.NoOfSeats;
          // item.durationName =
          //   spaceResponse.data.SpaceDetails.length != 0
          //     ? spaceResponse.data.SpaceDetails[0].DurationName
          //     : "";

          return item;
        })
      );
      getDurationList(updatedSpaces, shotName);
    } catch (err) {
      alert("err");
    }
  };

  const getDurationList = async (responseData: any, shotName: any) => {
    try {
      const updatedDuration = await Promise.all(
        responseData.map(async (item: bookingList) => {
          const spaceResponse = await axios.get(
            `${baseURL}odata/Durations?$filter=Id eq ${item.durationName}`
          );
          item.durationName = spaceResponse.data.value[0].DurationType;

          return item;
        })
      );
      getBookingStatusList(updatedDuration, shotName);
    } catch (err) {
      alert("err");
    }
  };

  const getBookingStatusList = async (responseData: any, shotName: any) => {
    try {
      const updatedStatus = await Promise.all(
        responseData.map(async (item: bookingList) => {
          const spaceResponse = await axios.get(
            `${baseURL}odata/GetAllBookingStatus`
          );
          for (let c = 0; c < spaceResponse.data.length; c++) {
            if (Number(item.status) === Number(spaceResponse.data[c].id)) {
              item.status = spaceResponse.data[c].name;
              if (Number(spaceResponse.data[c].id) === 1) {
                item.statusTextColor = "#319E03";
                item.statusColor = "#E2F2DC";
              } else if (Number(spaceResponse.data[c].id) === 2) {
                item.statusTextColor = "#005456";
                item.statusColor = "#BEF4F6";
              } else if (Number(spaceResponse.data[c].id) === 3) {
                item.statusTextColor = "#D51504";
                item.statusColor = "#FDDAD6 ";
              } else if (Number(spaceResponse.data[c].id) === 4) {
                item.statusTextColor = "#757575";
                item.statusColor = "#EEEEEE";
              } else {
                item.statusTextColor = "#000";
                item.statusColor = "#fff";
              }
              break;
            }
          }
          return item;
        })
      );
      getSpaceImageList(updatedStatus, shotName);
    } catch (err) {
      alert("err");
    }
  };

  const getSpaceImageList = async (responseData: any, shotName: any) => {
    try {
      const updatedSpacesImage = await Promise.all(
        responseData.map(async (item: bookingList) => {
          const spaceResponse = await axios.get(
            `${baseURL}odata/DocumentUploads?$filter=refno eq ${item.spaceId} and contains(reftype, 'Space')`
          );
          item.spaceImage =
            spaceResponse.data.value[
              spaceResponse.data.value.length - 1
            ].ImagePath;
          return item;
        })
      );
      let sortArrayList;
      if (shotName === true) {
        sortArrayList = [...updatedSpacesImage].sort((a, b) => {
          return a.spaceName.localeCompare(b.spaceName);
        });
      } else {
        sortArrayList = updatedSpacesImage;
      }
      setBookingDetailsData(sortArrayList);
      setBookingDetailsOnGoingData(filterBookings("Active", sortArrayList));
      setBookingDetailsUpcomingData(filterBookings("Upcoming", sortArrayList));
      setBookingDetailsCompletedData(
        filterBookings("Past Booking", sortArrayList)
      );
      setBookingDetailsCancelledData(
        filterBookings("Cancelled", sortArrayList)
      );
      setLoading(false);
    } catch (err) {
      alert(err);
    }
  };

  // Login status
  const handleLoginLogout = () => {
    setLogoutDialog(true);
    setShowPopup(false); // Close the popup after clicking
  };

  // Popup visibility
  const handleProfileClick = () => {
    setShowPopup(!showPopup);
  };

  // Filtering and Tabs
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (e: any) => {
    setSelectedTab(e.selected);
  };

  const sortOptions = [
    { id: 1, text: "Sort by Date", value: "date" },
    { id: 2, text: "Sort by Name", value: "name" },
  ];

  const handleViewBookingDetails = (e: any) => {
    for (let b = 0; b < myBookingDetails.length; b++) {
      if (e.id === myBookingDetails[b].Id) {
        navigateBookingDetails(myBookingDetails[b]);
        break;
      }
    }
  };

  const navigateBookingDetails = (bookingDetails: any) => {
    for (let c = 0; c < bookingDetailsData.length; c++) {
      if (bookingDetails.Id === bookingDetailsData[c].id) {
        const customBooking = {
          Id: bookingDetails.Id,
          UserId: bookingDetails.UserId,
          RoomTypeId: bookingDetails.RoomTypeId,
          TaxId: bookingDetails.TaxId,
          BookingId: bookingDetails.BookingId,
          BookingDatetime: bookingDetails.BookingDatetime,
          NoOfSeats: bookingDetails.NoOfSeats,
          BookingForId: bookingDetails.BookingForId,
          Address: bookingDetails.Address,
          CompanyName: bookingDetails.CompanyName,
          kycDocumentId: bookingDetails.kycDocumentId,
          GSTNumber: bookingDetails.GSTNumber,
          CustomerName: bookingDetails.CustomerName,
          CustomerType: bookingDetails.CustomerType,
          ContactNo: bookingDetails.ContactNo,
          Email: bookingDetails.Email,
          TotalAmount: bookingDetails.TotalAmount,
          PackageSelection: bookingDetails.PackageSelection,
          BookingFrom: bookingDetails.BookingFrom,
          BookingTo: bookingDetails.BookingTo,
          BookingStatus: bookingDetails.BookingStatus,
          PaymentStatus: bookingDetails.PaymentStatus,
          Deposit: bookingDetails.Deposit,
          SpaceRent: bookingDetails.SpaceRent,
          OtherAmount: bookingDetails.OtherAmount,
          DurationId: bookingDetails.DurationId,
          NoOfMonths: bookingDetails.NoOfMonths,
          SpaceId: bookingDetails.SpaceId,
          SpaceImage: bookingDetailsData[c].spaceImage,
          SpaceName: bookingDetailsData[c].spaceName,
          DurationName: bookingDetailsData[c].durationName,
          Status: bookingDetailsData[c].status,
          StatusTextColor: bookingDetailsData[c].statusTextColor,
          StatusColor: bookingDetailsData[c].statusColor,
          SpaceSeatCount: bookingDetailsData[c].spaceSeatCount,
        };
        navigate("/bookings/bookingdetails", {
          state: { bookingDetail: customBooking },
        });
      }
    }
  };

  const navigateFoodOrder = (e: any) => {
    sessionStorage.setItem("booking_id", e.id);
    navigate("/foodorders");
  };

  // Filter bookings based on the selected tab
  const filterBookings = (status: any, bookingDetailData: any[]) => {
    return bookingDetailData.filter((booking) => booking.status === status);
  };

  // menu flow
  const navigateMyProfile = () => {
    navigate("/myprofile");
  };

  const logoutHandler = (isLogout: any) => {
    if (isLogout === true) {
      removeUserDetails();
    }
    setLogoutDialog(false);
  };

  const removeUserDetails = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("customer_type");
    localStorage.removeItem("company_name");
    localStorage.removeItem("name");
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    localStorage.removeItem("address");
    localStorage.removeItem("address2");
    localStorage.removeItem("country");
    localStorage.removeItem("state");
    localStorage.removeItem("city");
    localStorage.removeItem("last_name");
    localStorage.removeItem("pin_code");
    localStorage.removeItem("kyc_document_id");
    localStorage.removeItem("gst_number");
    localStorage.removeItem("created");
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleDropdownChange = (e: DropDownListChangeEvent) => {
    const { name, value } = e.target;
    if (name === "shot") {
      setshotValue(value);
      if (value.value === "date") {
        getBookingList(false, true); // Date dec
      } else {
        getBookingList(true, false); // Name asc
      }
    }
  };

  const navigateMyOrders = () => {
    navigate("/myorders");
  };

  return (
    <div className="booking-container">
      <AppBar className="header-row">
        <AppBarSection>
          <img
            src={require("./assets/images/logo.png")}
            alt="Logo"
            style={{ height: "40px", marginRight: "20px" }}
          />
        </AppBarSection>
        <AppBarSection className="menu-alignment">
          <Label className="top-view top-selected">
            <Link to="/">Book a Space</Link>
          </Label>
        </AppBarSection>
        <AppBarSpacer style={{ flexGrow: 1 }} />
        <AppBarSection className="top-view">
          {profileImage === "" ? (
            <img
              ref={anchorRef}
              src={require("./assets/images/profile.png")}
              alt="Profile"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileClick}
            />
          ) : (
            <img
              ref={anchorRef}
              src={profileImage}
              alt="Profile"
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={handleProfileClick}
            />
          )}
          <Popup
            anchor={anchorRef.current}
            collision={{
              horizontal: "fit",
              vertical: "flip",
            }}
            show={showPopup}
            popupClass={"popup-content"}
            animate={false}
          >
            <div ref={popupRef} style={{ padding: "10px" }}>
              {loggedIn && (
                <div>
                  <Label
                    style={{ color: "#42B677" }}
                  >{`Welcome ${localStorage.getItem("name")}`}</Label>
                </div>
              )}
              {loggedIn && (
                <div>
                  <Button
                    fillMode="link"
                    className="popup-button-block popup-button-color"
                  >
                    My Bookings
                  </Button>
                  <Button
                    fillMode="link"
                    className="popup-button-block"
                    onClick={navigateMyOrders}
                  >
                    My Orders
                  </Button>
                  <Button
                    fillMode="link"
                    className="popup-button-block"
                    onClick={navigateMyProfile}
                  >
                    My Profile
                  </Button>
                </div>
              )}
              <Button fillMode="link" onClick={handleLoginLogout}>
                {loggedIn ? "Logout" : "Login"}
              </Button>
            </div>
          </Popup>
        </AppBarSection>
      </AppBar>
      <h2 className="bookings-header">My Bookings</h2>
      {loading && (
        <div className="loading-screen">
          <Loader size="large" type={"infinite-spinner"} />
        </div>
      )}
      {!loading && (
        <div>
          {bookingDetailsData.length == 0 ? (
            <div className="center-screen">No bookings data found</div>
          ) : (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="form-content-shedular-accordian">
                <div className="sort-filter-container">
                  <DropDownList
                    data={sortOptions}
                    value={shotValue}
                    name="shot"
                    textField="text"
                    dataItemKey="value"
                    defaultValue={defaultShot}
                    className="custom-dropdown"
                    onChange={handleDropdownChange}
                  />
                </div>
                <div style={{ padding: "20px" }}>
                  {/* Tabs */}
                  <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                    <TabStripTab title="ALL">
                      <div className="booking-list">
                        {bookingDetailsData.map((bookingDataList: any) => (
                          <div className="listview-container">
                            <div className="listview-imageContainer">
                              <img
                                src={`${imageURL}${bookingDataList.spaceImage}`}
                                alt="Lounge Space"
                                className="listview-image"
                              />
                            </div>
                            <div className="custom-details-container row">
                              <div className="custom-details-body">
                                <div className="custom-detail-item col-2">
                                  <Label className="custom-room-title">
                                    {bookingDataList.spaceName}
                                  </Label>
                                  {/* <div style={{ height: "10px" }}></div> */}
                                  <div
                                    className="custom-status-badge"
                                    style={{
                                      backgroundColor:
                                        bookingDataList.statusColor,
                                      color: bookingDataList.statusTextColor,
                                    }}
                                  >
                                    {bookingDataList.status}
                                  </div>
                                </div>
                                <div className="custom-detail-item col-2">
                                  <Label className="custom-label">
                                    Booking ID:
                                  </Label>
                                  <Label className="custom-value">
                                    {bookingDataList.bookingId}
                                  </Label>
                                </div>

                                <div className="custom-detail-item col-1">
                                  <Label className="custom-label">
                                    Duration:
                                  </Label>
                                  <Label className="custom-value">
                                    {bookingDataList.durationName === ""
                                      ? "-"
                                      : bookingDataList.durationName}
                                  </Label>
                                </div>

                                <div className="custom-detail-item col-2">
                                  <Label className="custom-label">
                                    Booking Date:
                                  </Label>
                                  <Label className="custom-value">{`${bookingDataList.bookingFrom}`}</Label>
                                </div>
                              </div>

                              {bookingDetailsData.length === 0 && (
                                <div className="booking-listview-no-data">
                                  No bookings data found
                                </div>
                              )}
                            </div>
                            <div className="listview-buttonContainer">
                              <Button
                                className="listview-button"
                                onClick={(e) =>
                                  handleViewBookingDetails(bookingDataList)
                                }
                              >
                                VIEW DETAILS
                              </Button>
                              {bookingDataList.status === "Active" && (
                                <Button
                                  className="listview-button"
                                  onClick={(e) =>
                                    navigateFoodOrder(bookingDataList)
                                  }
                                >
                                  FOOD ORDER
                                </Button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabStripTab>
                    <TabStripTab title="ACTIVE">
                      {bookingDetailsOnGoingData.length > 0 ? (
                        <div className="booking-list">
                          {filterBookings("Active", bookingDetailsData).map(
                            (bookingDataList) => (
                              <div className="listview-container">
                                <div className="listview-imageContainer">
                                  <img
                                    src={`${imageURL}${bookingDataList.spaceImage}`}
                                    alt="Lounge Space"
                                    className="listview-image"
                                  />
                                </div>
                                <div className="custom-details-container row">
                                  <div className="custom-details-body">
                                    <div className="custom-detail-item col-2">
                                      <Label className="custom-room-title">
                                        {bookingDataList.spaceName}
                                      </Label>
                                      {/* <div style={{ height: "10px" }}></div> */}
                                      <div
                                        className="custom-status-badge"
                                        style={{
                                          backgroundColor:
                                            bookingDataList.statusColor,
                                          color:
                                            bookingDataList.statusTextColor,
                                        }}
                                      >
                                        {bookingDataList.status}
                                      </div>
                                    </div>
                                    <div className="custom-detail-item col-2">
                                      <Label className="custom-label">
                                        Booking ID:
                                      </Label>
                                      <Label className="custom-value">
                                        {bookingDataList.bookingId}
                                      </Label>
                                    </div>

                                    <div className="custom-detail-item col-1">
                                      <Label className="custom-label">
                                        Duration:
                                      </Label>
                                      <Label className="custom-value">
                                        {bookingDataList.durationName === ""
                                          ? "-"
                                          : bookingDataList.durationName}
                                      </Label>
                                    </div>

                                    <div className="custom-detail-item col-2">
                                      <Label className="custom-label">
                                        Booking Date:
                                      </Label>
                                      <Label className="custom-value">{`${bookingDataList.bookingFrom}`}</Label>
                                    </div>
                                  </div>

                                  {bookingDetailsData.length === 0 && (
                                    <div className="booking-listview-no-data">
                                      No bookings data found
                                    </div>
                                  )}
                                </div>
                                <div className="listview-buttonContainer">
                                  <Button
                                    className="listview-button"
                                    onClick={(e) =>
                                      handleViewBookingDetails(bookingDataList)
                                    }
                                  >
                                    VIEW DETAILS
                                  </Button>
                                  <Button
                                    className="listview-button"
                                    onClick={(e) =>
                                      navigateFoodOrder(bookingDataList)
                                    }
                                  >
                                    FOOD ORDER
                                  </Button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="center-screen">
                          No bookings data found
                        </div>
                      )}
                    </TabStripTab>
                    <TabStripTab title="UPCOMING">
                      {bookingDetailsUpcomingData.length > 0 ? (
                        <div className="booking-list">
                          {filterBookings("Upcoming", bookingDetailsData).map(
                            (bookingDataList) => (
                              <div className="listview-container">
                                <div className="listview-imageContainer">
                                  <img
                                    src={`${imageURL}${bookingDataList.spaceImage}`}
                                    alt="Lounge Space"
                                    className="listview-image"
                                  />
                                </div>
                                <div className="custom-details-container row">
                                  <div className="custom-details-body">
                                    <div className="custom-detail-item col-2">
                                      <Label className="custom-room-title">
                                        {bookingDataList.spaceName}
                                      </Label>
                                      {/* <div style={{ height: "10px" }}></div> */}
                                      <div
                                        className="custom-status-badge"
                                        style={{
                                          backgroundColor:
                                            bookingDataList.statusColor,
                                          color:
                                            bookingDataList.statusTextColor,
                                        }}
                                      >
                                        {bookingDataList.status}
                                      </div>
                                    </div>
                                    <div className="custom-detail-item col-2">
                                      <Label className="custom-label">
                                        Booking ID:
                                      </Label>
                                      <Label className="custom-value">
                                        {bookingDataList.bookingId}
                                      </Label>
                                    </div>

                                    <div className="custom-detail-item col-1">
                                      <Label className="custom-label">
                                        Duration:
                                      </Label>
                                      <Label className="custom-value">
                                        {bookingDataList.durationName === ""
                                          ? "-"
                                          : bookingDataList.durationName}
                                      </Label>
                                    </div>

                                    <div className="custom-detail-item col-2">
                                      <Label className="custom-label">
                                        Booking Date:
                                      </Label>
                                      <Label className="custom-value">{`${bookingDataList.bookingFrom}`}</Label>
                                    </div>
                                  </div>
                                  {bookingDetailsData.length === 0 && (
                                    <div className="booking-listview-no-data">
                                      No bookings data found
                                    </div>
                                  )}
                                </div>
                                <div className="listview-buttonContainer">
                                  <Button
                                    className="listview-button"
                                    onClick={(e) =>
                                      handleViewBookingDetails(bookingDataList)
                                    }
                                  >
                                    VIEW DETAILS
                                  </Button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="center-screen">
                          No bookings data found
                        </div>
                      )}
                    </TabStripTab>
                    <TabStripTab title="PAST BOOKING">
                      {bookingDetailsCompletedData.length > 0 ? (
                        <div className="booking-list">
                          {filterBookings(
                            "Past Booking",
                            bookingDetailsData
                          ).map((bookingDataList) => (
                            <div className="listview-container">
                              <div className="listview-imageContainer">
                                <img
                                  src={`${imageURL}${bookingDataList.spaceImage}`}
                                  alt="Lounge Space"
                                  className="listview-image"
                                />
                              </div>
                              <div className="custom-details-container row">
                                <div className="custom-details-body">
                                  <div className="custom-detail-item col-2">
                                    <Label className="custom-room-title">
                                      {bookingDataList.spaceName}
                                    </Label>
                                    {/* <div style={{ height: "10px" }}></div> */}
                                    <div
                                      className="custom-status-badge"
                                      style={{
                                        backgroundColor:
                                          bookingDataList.statusColor,
                                        color: bookingDataList.statusTextColor,
                                      }}
                                    >
                                      {bookingDataList.status}
                                    </div>
                                  </div>
                                  <div className="custom-detail-item col-2">
                                    <Label className="custom-label">
                                      Booking ID:
                                    </Label>
                                    <Label className="custom-value">
                                      {bookingDataList.bookingId}
                                    </Label>
                                  </div>

                                  <div className="custom-detail-item col-1">
                                    <Label className="custom-label">
                                      Duration:
                                    </Label>
                                    <Label className="custom-value">
                                      {bookingDataList.durationName === ""
                                        ? "-"
                                        : bookingDataList.durationName}
                                    </Label>
                                  </div>

                                  <div className="custom-detail-item col-2">
                                    <Label className="custom-label">
                                      Booking Date:
                                    </Label>
                                    <Label className="custom-value">{`${bookingDataList.bookingFrom}`}</Label>
                                  </div>
                                </div>

                                {bookingDetailsData.length === 0 && (
                                  <div className="booking-listview-no-data">
                                    No bookings data found
                                  </div>
                                )}
                              </div>
                              <div className="listview-buttonContainer">
                                <Button
                                  className="listview-button"
                                  onClick={(e) =>
                                    handleViewBookingDetails(bookingDataList)
                                  }
                                >
                                  VIEW DETAILS
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="center-screen">
                          No bookings data found
                        </div>
                      )}
                    </TabStripTab>
                    <TabStripTab title="CANCELLED">
                      {bookingDetailsCancelledData.length > 0 ? (
                        <div className="booking-list">
                          {filterBookings("Cancelled", bookingDetailsData).map(
                            (bookingDataList) => (
                              <div className="listview-container">
                                <div className="listview-imageContainer">
                                  <img
                                    src={`${imageURL}${bookingDataList.spaceImage}`}
                                    alt="Lounge Space"
                                    className="listview-image"
                                  />
                                </div>
                                <div className="custom-details-container row">
                                  <div className="custom-details-body">
                                    <div className="custom-detail-item col-2">
                                      <Label className="custom-room-title">
                                        {bookingDataList.spaceName}
                                      </Label>
                                      {/* <div style={{ height: "10px" }}></div> */}
                                      <div
                                        className="custom-status-badge"
                                        style={{
                                          backgroundColor:
                                            bookingDataList.statusColor,
                                          color:
                                            bookingDataList.statusTextColor,
                                        }}
                                      >
                                        {bookingDataList.status}
                                      </div>
                                    </div>
                                    <div className="custom-detail-item col-2">
                                      <Label className="custom-label">
                                        Booking ID:
                                      </Label>
                                      <Label className="custom-value">
                                        {bookingDataList.bookingId}
                                      </Label>
                                    </div>

                                    <div className="custom-detail-item col-1">
                                      <Label className="custom-label">
                                        Duration:
                                      </Label>
                                      <Label className="custom-value">
                                        {bookingDataList.durationName === ""
                                          ? "-"
                                          : bookingDataList.durationName}
                                      </Label>
                                    </div>

                                    <div className="custom-detail-item col-2">
                                      <Label className="custom-label">
                                        Booking Date:
                                      </Label>
                                      <Label className="custom-value">{`${bookingDataList.bookingFrom}`}</Label>
                                    </div>
                                  </div>

                                  {bookingDetailsData.length === 0 && (
                                    <div className="booking-listview-no-data">
                                      No bookings data found
                                    </div>
                                  )}
                                </div>
                                <div className="listview-buttonContainer">
                                  <Button
                                    className="listview-button"
                                    onClick={(e) =>
                                      handleViewBookingDetails(bookingDataList)
                                    }
                                  >
                                    VIEW DETAILS
                                  </Button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="center-screen">
                          No bookings data found
                        </div>
                      )}
                    </TabStripTab>
                  </TabStrip>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Logout dialog */}
      {logoutDialog && (
        <Dialog
          onClose={() => setLogoutDialog(false)}
          className="dialog-container"
        >
          <div className="dialog-content">
            <Label className="dialog-heading">Alert Dialog</Label>
            <Label>Are you sure you want to logout?</Label>
            <div className="login-button-content">
              <Button
                className="login-button-dialog"
                onClick={(e) => logoutHandler(true)}
              >
                Yes
              </Button>
              <Button
                className="login-button-dialog"
                onClick={(e) => logoutHandler(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
