import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./styles.css";
import { BookingForm } from "./RoomBooking";
import { MyBookingsList } from "./MyBookings";
import { BookingDetail } from "./BookingDetail";
import { MyProfile } from "./MyProfile";
import { FoodOrders } from "./FoodOrders";
import { MyOrders } from "./MyOrders";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<BookingForm />} />
        <Route path="/bookings" element={<MyBookingsList />} />
        <Route path="/bookings/bookingdetails" element={<BookingDetail />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/foodorders" element={<FoodOrders />} />
        <Route path="/myorders" element={<MyOrders />} />
      </Routes>
    </div>
  );
};
export default App;
